import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    padding: 10em 0;
    text-align:center;
    background-color:#F3F3F3;

    .animation {
        margin: 0 auto;
        max-width: 53.33em;
    }
    .headline {
        font-size: 2em;
        font-weight: 700;
        margin-bottom: 1em;
    }

    .txt {
        font-size: 1.1em;
    }

    .control {
        padding-top: 2.7em;
    }

    .layout-button {
        margin-bottom: 1em;
    }

    .homepage-link {
        color: #000000;
        font-size: .85em;
    }

    @media(max-width: ${variables.mobileM}) {
        overflow: hidden;

        .animation {
            width: 178vw;
            transform: translateX(-22vw);
        }
    }
`;