import { useContext } from 'react';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonLayouts } from 'components/layout/Button';
import Head from 'components/layout/Head';
import LayoutContainer from 'components/layout/LayoutContainer';
import Spinner from 'components/layout/Spinner/component';
import LayoutDefault from 'components/modules/public/layouts/Default';

import StyledComponent from './styles';
import { Props } from './types';

const Dynamic404Animation = dynamic(
    () => import('components/modules/public/layout/animations/404PageAnimation'),
    {
        loading: () => <Spinner />,
        ssr: false,
    }
);

const PublicPage404: NextPage<Props> = () => {
    const localeContext = useContext(LocaleContext);
    const data = localeContext?.translations?.notFound;

    return (
        <>
            <Head {...data.meta} />
            <LayoutDefault>
                <StyledComponent className="public-page-notfound">
                    <Dynamic404Animation />
                    <LayoutContainer {...data.content.layoutContainerProps}>
                        <p className="headline">
                            {data.content.title}
                        </p>
                        <p className="txt">
                            {data.content.txt}
                        </p>
                        <Button
                            className="control"
                            layout={ButtonLayouts.Wide}
                            {...data.content.buttonProps}
                        />
                        <Link
                            href={data.content.linkProps.path}
                            prefetch={false}
                        >
                            <a className="homepage-link">
                                {data.content.linkProps.label}
                            </a>
                        </Link>
                    </LayoutContainer>
                </StyledComponent>
            </LayoutDefault>
        </>
    );
};

export default PublicPage404;
